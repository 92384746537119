import React from "react";
import "../../App.css";
import Whyervesta from "../Whyervesta";
import StepsChart from "../StepsChart";
import HowItWorkscomp from "../HowItWorkscomp";
import Commissions from "../Commissions";
import Details from "../Details";
import Footer from "../Footer";
function HowItWorks() {
 
    

  return (
    <>
      <Details/>
      <Whyervesta/>
      <HowItWorkscomp/>
      <div style={{padding:"50px",textAlign:"center"}}>
          <img className="navbar-logo-img-large" src="images/ervestayazilim-logo-seffaf.png"></img>
       </div>
       <Footer/>
    </>
  );
}

export default HowItWorks;