import React from "react"
import {Link} from "react-router-dom"
import CardItem from "./CardItem";
import "./Cards.css";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import { Text} from "react-native";

function Cards() {
const {t, i18n} = useTranslation();
  return (
    <>
        <div className="cards">
            <h1>{t("brandtitle")}</h1><br/>
            <view className="cards__container"><text className="form__text5">{t("visitbrandsweb")}</text></view>
            <view className="cards__container"><text className="form__text5">{t("visitbrandsweb4")}</text><br/></view>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem 
                        src="https://blogs.windows.com/wp-content/uploads/prod/2020/08/windows-logo-social.png"
                        text="WINDOWS" 
                        label="All Products"
                        path="https://www.microsoft.com/en-us/windows?r=1" />

                        <CardItem 
                        src="https://cdn.worldvectorlogo.com/logos/logo-android.svg"
                        text="ANDROID" 
                        label="Yazilim Image"
                        path="https://www.android.com/" />
                                                <CardItem 
                        src="https://www.webtekno.com/images/editor/default/0002/70/4269a326abc3fb7f0c57f26cb80e812d248f3b04.jpeg"
                        text="IOS" 
                        label="Yazilim Image"
                        path="https://www.apple.com/ios/ios-16/" />
                    </ul>
                    <view className="cards__container"><br/><text className="form__text5">{t("visitbrandsweb2")}</text><br/></view>
                    <ul className="cards__items">
                        <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Unity_Technologies_logo.svg/2560px-Unity_Technologies_logo.svg.png"
                        text="UNITY" 
                        label="All Products"
                        path="https://www.unity.com" />

                        <CardItem 
                        src="https://www.datocms-assets.com/45470/1631026680-logo-react-native.png"
                        text="REACT-NATIVE" 
                        label="Yazilim Image"
                        path="https://reactnative.dev/" />
                                                <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Android_Studio_Icon_3.6.svg/512px-Android_Studio_Icon_3.6.svg.png?20210301045217"
                        text="ANDROID STUDIO" 
                        label="Yazilim Image"
                        path="https://developer.android.com/studio" />
                                                
                    </ul>
                    <ul className="cards__items">
                        
                    <CardItem 
                        src="https://www.nuget.org/profiles/aspnet/avatar?imageSize=512"
                        text="ASP.NET" 
                        label="All Products"
                        path="https://dotnet.microsoft.com/en-us/apps/aspnet" />

                    <CardItem 
                        src="https://docs.flutter.dev/assets/images/shared/brand/flutter/logo/flutter-lockup.png"
                        text="FLUTTER" 
                        label="Yazilim Image"
                        path="https://docs.flutter.dev/" />
                                                <CardItem 
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTPLyZmWC_WokNkTLa1PGAoO_SoUdWI1D8cgg3-GMTu2_CF6BsIc9WHompavK1kxgIw5w&usqp=CAU"
                        text="VISUALBASIC" 
                        label="Yazilim Image"
                        path="https://learn.microsoft.com/en-us/dotnet/visual-basic/" />
                                                <CardItem 
                        src="https://is2-ssl.mzstatic.com/image/thumb/Purple112/v4/a6/72/a7/a672a7fc-187d-282b-a25e-eb2bded2596c/Xcode-85-220-0-4-2x-sRGB.png/1200x630bb.png"
                        text="XCODE" 
                        label="Yazilim Image"
                        path="https://developer.apple.com/xcode/" />
                    </ul>
                    <view className="cards__container"><br/><text className="form__text5">{t("visitbrandsweb3")}</text><br/></view>
                    
                    <ul className="cards__items">
                    <CardItem 
                        src="https://gelecegiyazanlar.turkcell.com.tr/sites/default/files/blog/content/2020/image-firebase0001_0.png"
                        text="FIREBASE" 
                        label="All Products"
                        path="https://firebase.google.com/" />

                        <CardItem 
                        src="https://m.media-amazon.com/images/G/41/social_share/amazon_logo._CB633269640_.png"
                        text="AMAZONDB" 
                        label="Yazilim Image"
                        path="https://aws.amazon.com/?nc2=h_lg" />
                                               

                    </ul>
                    <ul className="cards__items">
                        
                    <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/320px-MongoDB_Logo.svg.png"
                        text="MONGODB" 
                        label="All Products"
                        path="https://www.mongodb.com/" />

                        <CardItem 
                        src="https://d1.awsstatic.com/asset-repository/products/amazon-rds/1024px-MySQL.ff87215b43fd7292af172e2a5d9b844217262571.png"
                        text="MYSQL" 
                        label="Yazilim Image"
                        path="https://www.mysql.com/" />
                        <CardItem 
                        src="https://mma.prnewswire.com/media/467598/Oracle_Logo.jpg?p=publish"
                        text="ORACLE" 
                        label="All Products"
                        path="https://www.oracle.com/" />

                        <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1024px-Postgresql_elephant.svg.png"
                        text="PostgreSQL" 
                        label="Yazilim Image"
                        path="https://www.postgresql.org/" />
                                               
                    </ul>
                    
                    <view className="cards__container"><br/><text className="form__text5">{t("visitbrandsweb5")}</text><br/></view>
                    
                    <ul className="cards__items">
                        
                        <CardItem 
                            src="https://global-uploads.webflow.com/6097e0eca1e875de53031ff6/61bb05edca00197f2e7a19cf_Csharp_Logo.png"
                            text="C#" 
                            label="All Products"
                            path="" />
    
                            <CardItem 
                            src="https://anthoncode.com/wp-content/uploads/2019/01/c-logo-png.png"
                            text="C++" 
                            label="Yazilim Image"
                            path="" />
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/1200px-Java_programming_language_logo.svg.png"
                            text="JAVA" 
                            label="All Products"
                            path="" />
    
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/2048px-Unofficial_JavaScript_logo_2.svg.png"
                            text="JAVASCRIPT" 
                            label="Yazilim Image"
                            path="" />
    
    
                        </ul>
                        <ul className="cards__items">
                        
                        <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/7/74/Kotlin_Icon.png"
                            text="KOTLIN" 
                            label="All Products"
                            path="" />
    
                            <CardItem 
                            src="https://developer.apple.com/swift/images/swift-og.png"
                            text="SWIFT" 
                            label="Yazilim Image"
                            path="" />
                            <CardItem 
                            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/objective-c/objective-c.png"
                            text="OBJECTIVE C" 
                            label="All Products"
                            path="" />
                            <CardItem 
                            src="https://dart.dev/assets/shared/dart-logo-for-shares.png?2"
                            text="DART(Flutter)" 
                            label="Yazilim Image"
                            path="" />
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/800px-Python-logo-notext.svg.png"
                            text="PYTHON" 
                            label="All Products"
                            path="" />
    
                        </ul>
                        <ul className="cards__items">
                        
                        <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1200px-HTML5_logo_and_wordmark.svg.png"
                            text="HTML" 
                            label="All Products"
                            path="" />
                            
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1200px-CSS3_logo_and_wordmark.svg.png"
                            text="CSS" 
                            label="All Products"
                            path="" />
                            <CardItem 
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/1200px-PHP-logo.svg.png"
                            text="PHP" 
                            label="Yazilim Image"
                            path="" />
                        </ul>
                        
                        <view className="cards__container"><br/><text className="form__text5">{t("visitbrandsweb6")}</text><br/></view>
                        <ul className="cards__items">
                        <CardItem 
                        src="https://www.adobe.com/content/dam/acom/one-console/icons_rebrand/ps_appicon.svg"
                        text="ADOBE PHOTOSHOP" 
                        label="All Products"
                        path="" />

                        <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/1200px-Adobe_XD_CC_icon.svg.png"
                        text="ADOBE XD" 
                        label="Yazilim Image"
                        path="" />
                                                
                                               
                    </ul>
                    <ul className="cards__items">
                    <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Sketch_Logo.svg/800px-Sketch_Logo.svg.png"
                        text="SKETCH" 
                        label="Yazilim Image"
                        path="" />
                                                <CardItem 
                        src="https://www.sketchappsources.com/resources/source-image/zeplin-logo.png"
                        text="ZEPLIN" 
                        label="Yazilim Image"
                        path="" />
                                               
                    </ul>
                    <ul className="cards__items">
                    <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Logo_Blender.svg/2560px-Logo_Blender.svg.png"
                        text="BLENDER" 
                        label="Yazilim Image"
                        path="" />
                                                <CardItem 
                        src="https://upload.wikimedia.org/wikipedia/commons/9/9c/SketchUp-Logo.png"
                        text="SKETCHUP" 
                        label="Yazilim Image"
                        path="" />
                                               
                    </ul>
                </div>
            </div>
        </div>
        <div style={{padding:"50px",textAlign:"center"}}>
        <img className="navbar-logo-img-large" src="images/ervestayazilim-logo-seffaf.png"></img>
       </div>
    </>
  );
}

export default Cards;