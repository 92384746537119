

import {Text,Image,View,TouchableOpacity,SafeAreaView} from "react-native";
import React, { useState } from 'react';
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function ForCostumer() {
 
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search); 
    const IdFromURL = params.get('uid'); 
    const {t, i18n} = useTranslation();

  return (
      <View style={{backgroundColor: 'white' , paddingBottom:"60px", paddingTop:"60px", textAlign:"center",flexDirection:"column",flexWrap:"wrap", flex:1,display:"flex",justifyContent:"center"}}>
      
      <View style={{marginLeft: "15%",marginRight:"15%",paddingBottom:"60px", paddingTop:"60px", textAlign:"center",flexDirection:"column",flexWrap:"wrap", flex:1,display:"flex",justifyContent:"center"}}>
      <h1>{t("costumerreviews")}</h1>
      <view className="cards__container"><text className="form__text5"></text></view>
      </View>
    <View style={styles.container1}>
        
        <View style={styles.container2}>
            <text className="form__text4">Alper T.</text>
            <text className="form__text6"> Oldukça vizyonlular ve yaptığı işi biliyorlar..  Birlikte çalışmaktan çok memnun kaldım. Projeyi kendilerinin gibi benimsiyorlar rahatlıkla güvenebilirsiniz.. Emeğinize sağlık. </text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
            </View>
        </View>
        <View style={styles.container2}>
            <text className="form__text4">Emre T.</text>
            <text className="form__text6">Projemizi sahiplenmiş olmaları ve sonuna kadar gösterdikleri ilgi ve çabaya teşekkür ederiz. Çalıştığımız yazılımcıları bilgi ve tecrübe açısından da çok iyi seviyededir. </text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
            </View>
        </View>
        <View style={styles.container2}>
            <text className="form__text4">Cansu C.</text>
            <text className="form__text6">Cok memnun kaldım 😊 işin hakkini ve aldığı ücretin karşılığını kat kat veriyor. Kesinlikle tavsiye ediyorum.</text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
            </View>
        </View>
        <View style={styles.container2}>
            <text className="form__text4">Abdullah M.</text>
            <text className="form__text6">Saygılı ve işini düzgün yapıyor.</text>
            <View style={styles.container11}>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/star.png" style={styles.imagecontainer2}/>
                <Image source="/images/stargray.png" style={styles.imagecontainer2}/>
            </View>
        </View>
    </View>
      </View>
  );
}

const styles = {
    container1: {
      marginTop: 5,
      flexDirection: "row",
      textAlign: 'center',
      flexWrap: "wrap",
      justifyContent: 'center',
      flex: 1,
      marginLeft: "10%",
      marginRight: "10%"
    },
    
    container11: {
        marginTop: 10,

        flexDirection: "row",
        textAlign: 'center',
        flexWrap: false,
        flex: 1,
      },
    imagecontainer2: {
        width: 25,
        height: 25,
        marginRight: 4,
        textAlign: 'center',
        marginBottom: 5,
    },
    container2: {
      marginTop: 10,
      marginLeft: 30,
      minWidth: 130,
      flexDirection: "column",
      alignItems: "left",
      justifyContent:"flex-start",
      textAlign: 'left',
      flex: 1,
    },
    
    buttoncontainer: {
        marginRight: "30%",
        marginLeft: "30%",
        textAlign: 'center',
        marginTop: 5,
    },
    smallerbuttoncontainer: {
        marginRight: "35%",
        marginLeft: "35%",
        textAlign: 'center',
        marginTop: 5,
        marginBottom:20
    },
    imagecontainer: {
        width: 30,
        height: 30,
        textAlign: 'center',
        marginBottom: 2,
        flex:1,
    },
    rowcontainer2: {
        width: "100%",
        textAlign: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        flex:1,
    },
    infobarcontainer: {
      textAlign: 'left',
      marginBottom: 5,
      flex:5,
    },
    statuscontainer: {
      textAlign: 'center',
      marginBottom: 0,
      marginLeft: 5,
      flex:1.2,
    },
    actionscontainer: {
      textAlign: 'center',
      marginBottom: 5,
      marginLeft: 5,
      flex:1.2,
      justifyContent: 'space-between',
    },
    rowcontainer: {width: "95%",
      textAlign: 'left',
      flexDirection: "row",
      marginBottom: 5,
      justifyContent: 'space-between',
      flex:1.2,
    },
  }

export default ForCostumer;

