import React from "react";
import "../../App.css";
import { View, Text,Image,CheckBox} from 'react-native';
import Cards from "../Cards";
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import Footer from "../Footer";

function AboutUs() {
 
    
const {t, i18n} = useTranslation();

  return (
    <>

        <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"10%",marginRight:"10%", textAlign:"center",flexDirection:"row",flex:1,display:"flex",justifyContent:"center"}}>
            <View style={{width:"60%",minWidth:"300px", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
                <text style={{fontStyle: "bold", fontSize:"50px", fontFamily:"Trebuchet MS"}}>{t("aboutus")}</text><br/><br/>
                <text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext1")}</text><br/>
                <text style={{fontStyle: "bold", fontSize:"30px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext2")}</text>
                <text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext3")}</text>
                <text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext4")}</text><br/>
                <text style={{fontStyle: "bold", fontSize:"30px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext5")}</text>
                <ul style={{marginLeft:"30px", fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext6")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext7")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext8")}</text></li> 
                    <li><text style={{ fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext9")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext10")}</text></li> 
                </ul><br/>
                <text style={{ fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("aboutustext11")}</text>
                <br/>
           </View>
        </View>
        <br/><br/>
        <div style={{padding:"50px",textAlign:"center"}}>
          <img className="navbar-logo-img-large" src="images/ervestayazilim-logo-seffaf.png"></img>
       </div>
      <Footer/>

    </>
  );
}

export default AboutUs;