//import '../../App.css';
import { Button } from "../Button";
import React,{useState,useRef,useEffect} from 'react';
import {StyleSheet,CheckBox, TextInput ,Text, Image} from "react-native";
import { Link } from 'react-router-dom';
import Footer from "../Footer";
//import MaskInput from 'react-native-mask-input';
import MaskInput from 'react-maskinput';
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import BrandsTicker from "../BrandsTicker";
import Cards2 from "../Cards2";

let isfirsttime =  1;

function Services() {

  
const {t, i18n} = useTranslation();
  

    return(
      <div className="topbody">
      <text className="form__titellabel" for="firstName">{t("placeanorder")}</text><br /><br/>
                           
   

      <Cards2/>
      <Footer/>
      </div>  
    )       
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
});


export default  Services;