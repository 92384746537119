import React, { useState } from 'react';
import "../../App.css";
import { View, Text,Image,CheckBox} from 'react-native';
import Cards from "../Cards";
import '../../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import Footer from "../Footer";
import { Button } from "../Button";
import { Link } from 'react-router-dom';

function AboutUs() {
  
    
const {t, i18n} = useTranslation();
const [signedin, setSignedIn] = useState(false);
    const changesignstatus = (mybool) => setSignedIn(mybool);
    const [isdisabled, setDisable] = useState(false);
    const [uidtext, setuidtext] = useState("uid");
    const changeuidtext = (text) => setuidtext(text);

   


  function validateemail(text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      return false;
    }
    else {return true;
    }
  }
  function validateproductinputs() {
    var outputmsg = "true";
    if(document.getElementById("topic").value.length<2){
      outputmsg = "Topic is too short.\n"
      document.getElementById("topic").style.border="2px solid red";
    }    
    if(document.getElementById("topic").value.length>1000){
      outputmsg = "Topic is too long. Maximum 1000 char allowed.\n"
      document.getElementById("topic").style.border="2px solid red";
    } 
    if(document.getElementById("message").value.length<2){
      outputmsg = "Message is too short.\n"
      document.getElementById("message").style.border="2px solid red";
    } 
    if(document.getElementById("message").value.length>2000){
      outputmsg = "Message is too long. Maximum 2000 char allowed.\n"
      document.getElementById("message").style.border="2px solid red";
    }
    if(!validateemail(document.getElementById("emailbox").value)){
      outputmsg = "Email format is not correct.\n"
      document.getElementById("emailbox").style.border="2px solid red";
    } 
    if(outputmsg == "true")
    return true;
    else {
      alert(outputmsg);
      setDisable(false);
      return false;}
  }





    //update this with your js_form selector
    var form_id_js = "javascript_form";

    var data_js = {
        "access_token": "kvr94pgngg2kgnqcrudw1f4o"
    };

    function js_onSuccess() {
      
      
      setDisable(true);
      alert(t("Succesful"));
      window.location.href = "./contact-us";
    }

    function js_onError(error) {
      
      setDisable(true);
      alert(t("Failed"+ error.message));
      window.location.href = "./contact-us";
    }

    function js_send() {
      
      
        var request = new XMLHttpRequest();
        request.onreadystatechange = function() {
            if (request.readyState == 4 && request.status == 200) {
                js_onSuccess();
            } else
            if(request.readyState == 4) {
                js_onError(request.response);
            }
        };

        //var subject = document.querySelector("#" + form_id_js + " [name='subject']").value;
        //var message = document.querySelector("#" + form_id_js + " [name='text']").value;
        //var emailtext = document.querySelector("#" + form_id_js + " [name='emailtext']").value;
        data_js['subject'] = document.getElementById("topic").value;
        data_js['text'] = document.getElementById("message").value +"\nemail: "+document.getElementById("emailbox").value; 
        var params = toParams(data_js);

        request.open("POST", "https://postmail.invotes.com/send", true);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        request.send(params);


        return false;
    }

    //sendButton.onclick = js_send;

    function toParams(data_js) {
        var form_data = [];
        for ( var key in data_js ) {
            form_data.push(encodeURIComponent(key) + "=" + encodeURIComponent(data_js[key]));
        }

        return form_data.join("&");
    }
/*
    var js_form = document.getElementById(form_id_js);
    js_form.addEventListener("submit", function (e) {
        e.preventDefault();
    });*/
    
    
  return (
    <>
        <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"10%",marginRight:"10%", textAlign:"center",flexDirection:"row",flex:1,display:"flex",justifyContent:"center"}}>
          <View style={{width:"80%",minWidth:"150px", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
                <text style={{fontStyle: "bold", fontSize:"50px", fontFamily:"Inter"}}>{t("contactus")}</text><br/><br/>
                <text style={{fontSize:"20px", textAlign:"center",}}>+90 531 826 48 04</text><br/>
                <text style={{fontSize:"20px", textAlign:"center",}}>info@ervesta.net</text><br/>
                <text style={{fontSize:"20px", textAlign:"center",}}>Kale mah. ışıklar cd 2/70 ERVESTA Yazılım 06950 Altındağ/Ankara TURKEY</text><br/>

          </View>
          
          <div class="form-body"><div class="gmap_canvas"><iframe width="500px" height="300px" id="gmap_canvas" src="https://maps.google.com/maps?q=ervesta%20yaz%C4%B1l%C4%B1m&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/><style>.mapouter{}</style><style>.gmap_canvas {}</style></div></div>
          <div className="form" id="my_form">
            <text className="form__titellabel" for="firstName">{t("contactus")}</text><br />
              <div className="form-body">
                  <div className="email">
                      <input  type="email" id="emailbox" name="emailtext" className="form__input" placeholder={t("email")}/>
                  </div>
                  <div className="password">
                    <input className="form__input"  type="text"  name="subject" id="topic" placeholder={t("topic")}/>
                  </div>
                  <div className="password">
                    <textarea cols="40" rows="5" name="text" className="form__input" style={{height:"150px"}} type="text"  id="message" placeholder={t("yourmessage")}/>
                  </div>
              </div>
              <div class="footer">
                  <Button buttonStyle= "btn--outline2" type="submit" id="js_send1" value="Send" onClick={() => {js_send()}}>{t("sendmessage")}{" >"}</Button>
              </div>
          </div>    
        </View>

        <br/><br/>
        <div style={{padding:"50px",textAlign:"center"}}>
            <img className="navbar-logo-img-large" src="images/ervestayazilim-logo-seffaf.png"></img>
       </div>

      <Footer/>

    </>
  );
}

export default AboutUs;