import React from "react";
import "../App.css";
import { View, Text,Image,CheckBox} from 'react-native';
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';

function AboutUs() {
 
    
const {t, i18n} = useTranslation();

  return (
    <>

        <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"10%",marginRight:"10%", textAlign:"center",flexDirection:"row",flex:1,display:"flex",justifyContent:"center"}}>
            <View style={{width:"60%",minWidth:"300px", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
                <text style={{fontStyle: "bold", fontSize:"50px", fontFamily:"Trebuchet MS"}}>{t("erevstausesvar4")}</text><br/><br/>
                <ul style={{marginLeft:"30px", fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar5")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar6")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar7")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar8")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar9")}</text></li> 
                </ul><br/>
           </View>
        </View>
        <View style={{flexWrap:"wrap",marginTop:"5%", marginLeft:"10%",marginRight:"10%", textAlign:"center",flexDirection:"row",flex:1,display:"flex",justifyContent:"center"}}>
            <View style={{width:"60%",minWidth:"300px", textAlign:"center",flexDirection:"column",flex:1,display:"flex",justifyContent:"center"}}>
                <text style={{fontStyle: "bold", fontSize:"50px", fontFamily:"Trebuchet MS"}}>{t("erevstausesvar1")}</text><br/><br/>
                <ul style={{marginLeft:"30px", fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar2")}</text></li> 
                    <li><text style={{fontSize:"20px", fontFamily:"Trebuchet MS",textAlign:"left",}}>{t("erevstausesvar3")}</text></li> 
                </ul><br/>
           </View>
        </View>

    </>
  );
}

export default AboutUs;