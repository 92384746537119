import React from "react"
import {Link} from "react-router-dom"
import CardItem2 from "./CardItem2";
import "./Cards2.css";
import '../languages/i18n'; 
import {useTranslation} from 'react-i18next';
import { Text} from "react-native";

function Cards() {
const {t, i18n} = useTranslation();
  return (
    <>
        <div className="cards">
            <h1>{t("brandtitle")}</h1><br/>
            <view className="cards__container"><text className="form__text5">{t("visitbrandsweb")}</text></view>
            <view className="cards__container"><text className="form__text5">{t("visitbrandsweb4")}</text><br/></view>
            <div className="cards__container">
                <div className="cards__wrapper2">
                    <ul className="cards__items">
                        <CardItem2 
                        src="https://play-lh.googleusercontent.com/yQxwqw-fGQ5dI-bBhubjeYrS03HjN6sEWelhSpgwdz-z3aXd9oMVPaixmpjP1jKof-E=w2560-h1440-rw"
                        text="BlockUp" 
                        label="All Products"
                        path="" />


<CardItem2 
                        src="/images/alertcoin.PNG"
                        text="AlertCoin" 
                        label="Yazilim Image"
                        path="" />
                                                <CardItem2 
                        src="/images/shopreminder.PNG"
                        text="Shop Reminder" 
                        label="Yazilim Image"
                        path="" />
                    </ul>
                    <ul className="cards__items">
                        <CardItem2 
                        src="https://play-lh.googleusercontent.com/WjdqLKrYwGd4xL1jPSuiWgy6jGpyczjSYNrrwNGeUcpLd2ieEMvlWME5BMv0o6OOuQ=w2560-h1440-rw"
                        text="Çarkı Çevir Kazan" 
                        label="Yazilim Image"
                        path="" />
                        <CardItem2 
                        src="/images/kazikazan.png"
                        text="Kazıkazan" 
                        label="Yazilim Image"
                        path="" />
                        <CardItem2 
                        src="https://play-lh.googleusercontent.com/aOWmhe1MkL8M003fdaWpUXE5scKMu66qBq95y_jvaR8A9lucA59QqlOohgoWS5ozbVI=w2560-h1440-rw"
                        text="Karele" 
                        label="Yazilim Image"
                        path="" />
                                                
                                                
                    </ul>
                    <ul className="cards__items">
                        
                    <CardItem2 
                        src="https://play-lh.googleusercontent.com/_4rqYtewtwYoOyVYEE4kHfvyIPZgVSQ-715qYA9KS4BufaYRwclHokjJcaNMGXzReQ=w2560-h1440-rw"
                        text="10 soru 10 cevap" 
                        label="Yazilim Image"
                        path="h" />
                                                <CardItem2 
                        src="https://play-lh.googleusercontent.com/CvPxPN9YpkAeGZE8YwG4zpf1qzrNdyNv-kiWgEioRt6iU-ml9PUN2TlhGXljGz3dIMc=w2560-h1440-rw"
                        text="Corner The Ball" 
                        label="Yazilim Image"
                        path="" />
                    <CardItem2 
                        src="/images/kasifbilir.PNG"
                        text="Kasif Bilir" 
                        label="Yazilim Image"
                        path="https://play.google.com/store/apps/details?id=com.agd.anadolugenclikbilgiyarismasi" />
                    </ul>

                    <ul className="cards__items">
                        
                    <CardItem2 
                        src="/images/kasifbilir.PNG"
                        text="Kasif Bilir" 
                        label="Yazilim Image"
                        path="" />

                    <CardItem2 
                        src="/images/bowling.PNG"
                        text="Bowling" 
                        label="Yazilim Image"
                        path="" />
                                                <CardItem2 
                        src="https://play-lh.googleusercontent.com/D1V4jwKfYjp2hvvESMnx-PU3IdQ9cvmpc4pkLDjOzayF-XHG4vsuzK6z6Sj5iiDX76Q=w2560-h1440-rw"
                        text="Swing & Jump" 
                        label="Yazilim Image"
                        path="https://play.google.com/store/apps/details?id=com.SwingJumpGame.SwingJump" />
                    </ul>
                    
            <view className="cards__container"><text className="form__text5">{t("visitbrandsweb7")}</text><br/></view>
                </div>
            </div>
        </div>
        <div style={{padding:"50px",textAlign:"center"}}>
            <img className="navbar-logo-img-large" src="images/ervestayazilim-logo-seffaf.png"></img>
       </div>
    </>
  );
}

export default Cards;