
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en.json';
import ru from './ru.json';
import tr from './tr.json';
  
i18n.use(initReactI18next).init({
  lng: 'tr',
  fallbackLng: 'tr',
  resources: {
    en: en,
    ru: ru,
    tr: tr,
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});
  
export default i18n;